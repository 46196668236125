import axios from 'axios';

export default {
  login (credentials) {
    return axios.post('/api/login', credentials);
  },
  logout () {
    return axios.post('/api/logout');
  },
  csrf () {
    return axios.get('/sanctum/csrf-cookie');
  },
  userInfo () {
    return axios.get('/api/user');
  },
  updatePassword (password) {
    return axios.post('/api/user/password', {password});
  },

  /* BEGIN TOKENS */
  fetchApiTokens () {
    return axios.get('/api/apitokens');
  },

  createApiToken (token) {
    return axios.post('/api/apitokens', token);
  },

  deleteApiToken (tokenId) {
    return axios.delete(`/api/apitokens/${tokenId}`);
  }


  /* END TOKENS */

};
