import getters from './getters';
import actions from './actions';
import mutations from './mutations';

// default state object
const state = {
  all: [],
  activeFieldId: 0,
  activeFieldType: '',
  types: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
