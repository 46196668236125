import axios from 'axios';

export default {
  fetch () {
    return axios.get('/api/qualifiertypes');
  },

  create (qualifiertype) {
    return axios.post('/api/qualifiertypes', qualifiertype);
  },

  update (qualifiertype) {
    return axios.put(`/api/qualifiertypes/${qualifiertype.id}`, qualifiertype);
  },

  delete(qualifiertypeId){
    return axios.delete(`/api/qualifiertypes/${qualifiertypeId}`);
  },

};
