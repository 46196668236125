export default {
    fetchTree (state, tree) {
        const exId = state.all.findIndex(a => a.clientId == tree.clientId);
        if (exId >= 0) {
            state.all.splice(exId, 1, tree);
            return;
        }
        state.all.push(tree);
    },


};
