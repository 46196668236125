<template>
  <div>
    <left-menu></left-menu>

    <v-app-bar

      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
      prominent
      :src="loggedIn ? '/img/transformers.jpg': ''"
      elevate-on-scroll
      shrink-on-scroll
    >
            <span class="hidden-sm-and-down" @click="$router.push('/').catch(()=>{})"
                  v-if="loggedIn"
            >
                <img src="/img/optimuslogo-white.png" class="logoimage"></img>
            </span>

      <v-toolbar-title v-if="!loggedIn">CGS Optimus</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon @click="toggleAwesome">
        <v-icon v-if="awesome">mdi-star</v-icon>
        <v-icon v-else="awesome">mdi-star-outline</v-icon>
      </v-btn>

      <v-btn icon v-if="loggedIn">
        <v-icon dark @click="logout">mdi-logout</v-icon>
      </v-btn>
      <v-btn icon v-if="!loggedIn">
        <v-icon dark @click="$router.push('/login').catch(()=>{})">mdi-login</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container
        fluid
        class="fill-height"
      >
        <router-view></router-view>

      </v-container>
    </v-main>
    <v-footer app>
      {{loggedIn}}
    </v-footer>
  </div>
</template>

<script>
  import LeftMenu from './LeftMenu';
  import { mapActions, mapState } from 'vuex';

  export default {
    name: 'Optimus',
    props: {
      source: String,
    },
    components: {
      LeftMenu,

    },
    data: () => ({
      dialog: false,
    }),
    computed: {
      ...mapState('user', {
        awesome: state => state.awesome,
        loggedIn: state => state.authenticated,
      }),
      route () {
        return this.$router.fullPath;
      }
    },
    methods: {
      ...mapActions('user', {
        toggleAwesome: 'toggleAwesome',
        deauth: 'logout',
      }),
      logout () {
        this.deauth().then(this.$router.push('/login').catch(() => {
        }));
      }
    },
    created () {
      if (!this.loggedIn && this.$route.name != 'Login') {
        this.$router.push({name: 'Login'}).catch(() => {
        });
      }
    },
    watch: {
      route: {
        handler (newVal, oldVal) {
          if (newVal.path == oldVal.path) {
            return;
          }

          if (!this.loggedIn) {
            this.$router.push('/login');
          }
        }
      },
      immediate: true
    }
  };
</script>

<style>
  .logoimage {
    height: 50px;
    margin-top: 5px;
  }
</style>
