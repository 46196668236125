import api from '@/api';

// this object should contain methods
export default {
    fetch ({commit}) {
        api.plans.fetch().then(({data}) => {
            commit('fetch', data);
        });
    },
    create ({commit}, plan) {
        api.plans.create(plan).then(({data}) => {
            commit('create', data);
        });
    },
    update ({commit}, plan) {
        api.plans.update(plan).then(({data}) => {
            commit('update', data);
        });
    },
    mapDivisions ({commit}, data) {
        api.plans.mapDivisions(data).then(({data}) => {
            return commit('update', data);
        });
    },
    fetchClientPlans({commit}, clientId){
        return api.plans.fetchClientPlans(clientId).then(({data})=>{
            return commit('fetch', data);
        })
    }
};
