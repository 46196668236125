export default {
    fetch (state, attributes) {
        state.all = attributes;
    },

    update (state, attribute) {
        const exId = state.all.findIndex(a => a.id == attribute.id);

        if (exId >=0) {
            state.all.splice(exId, 1, attribute);
        }

    },
};
