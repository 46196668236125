import api from '@/api';

// this object should contain methods
export default {
    fetch({commit}) {
        api.qualifiers.fetch().then(({data}) => {
            commit('fetch', data);
        });
    },
    fetchClientQualifiers({commit}, clientId) {
        api.qualifiers.fetchClientQualifiers(clientId).then(({data}) => {
            commit('fetch', data);
        });
    },
    create({commit}, qualifier){
        return api.qualifiers.create(qualifier).then(({data})=>{
            commit('create', data);
        });
    },
    update ({commit}, qualifier) {
        api.qualifiers.update(qualifier).then(({data}) => {
            commit('update', data);
        });
    },
    delete({commit}, qualifierId){
        api.qualifiers.delete(qualifierId).then(()=>{
            commit('delete', qualifierId);
        });
    },
    mapPlans({commit}, data){
        api.qualifiers.mapPlans(data).then(({data})=>{
            return commit('update', data);
        });
    }
};
