import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

axios.defaults.withCredentials = true;

Vue.use(VueAxios, axios);
const isLocal = [ 'localhost', '192.168.1' ].some(r => window.location.hostname.includes(r));
Vue.axios.defaults.baseURL = isLocal ? 'http://optimus.test/' : '/';

