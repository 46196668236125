export default {
    fetch (state, qualifieritems) {
        state.all = qualifieritems;
    },

    create (state, qualifieritem) {
        state.all.push(qualifieritem);
    },

    update (state, qualifieritem) {
        const exId = state.all.findIndex(a => a.id == qualifieritem.id);
        if (exId >= 0) {
            state.all.splice(exId, 1, qualifieritem);
        }
    },

    delete(state, qualifieritemId){
        const exId = state.all.findIndex(a => a.id == qualifieritemId);
        if (exId >= 0) {
            state.all.splice(exId, 1);
        }
    }
};
