/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Vue = require('vue');
import Vuetify from 'vuetify';

import axios from 'axios';
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import router from './router';
import './plugins/axios';
import Optimus from './components/Optimus';
import store from './store';
import './plugins/auth';
import VuetifyConfirm from 'vuetify-confirm';

import { VBtn, VIcon, VSnackbar } from 'vuetify/lib';

import VuetifyToast from 'vuetify-toast-snackbar-fbits';

import { VueMaskDirective } from 'v-mask';

import Vue2Filters from 'vue2-filters';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = '/';
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  console.log(error.response);
  if (error.response.status === 401) {
    store.dispatch('user/logout');
    router.push('/login');
  }
  return Promise.reject(error);
});

Vue.use(Vue2Filters);
Vue.use(Vuetify);
Vue.use(VuetifyToast);

Vue.directive('mask', VueMaskDirective);

const vuetifyOptions = {
  theme: {
    options: {
      customProperties: true,
    },
  },
};
const vuetify = new Vuetify(vuetifyOptions);
Vue.use(VuetifyConfirm, {vuetify});

const app = new Vue({
  components: {
    Optimus,
  },
  el: '#app',
  router,
  store,
  vuetify,
});
