export default {
  fetch (state, divisions) {
    state.all = [];
    state.all = divisions;
  },

  create (state, division) {
    state.all.push(division);
  },

  delete (state, divisionId) {
    const exId = state.all.findIndex(a => a.id == divisionId);
    if (exId >= 0) {
      state.all.splice(exId, 1);
    }
  },

  update (state, division) {
    const exId = state.all.findIndex(a => a.id == division.id);
    if (exId >= 0) {
      state.all.splice(exId, 1, division);
    }
  },

  setFetching (state, value) {
    state.all.fetchingDivisions = value;
  }
};
