import axios from 'axios';

export default {
    fetch () {
        return axios.get('/api/brokers');
    },
    create (broker) {
        return axios.post('/api/brokers', broker);
    },
    update(brokerData){
        return axios.put(`/api/brokers/${brokerData.id}`, brokerData);
    },
    load(brokerId, production){
        return axios.get(`/api/load/broker/${brokerId}?prod=${production ? 'true' : 'false'}`);
    }
};
