import api from '@/api';

// this object should contain methods
export default {
    fetch ({commit}) {
        api.tiers.fetch().then(({data}) => {
            commit('fetch', data);
        });
    },
    create ({commit}, tier) {
        api.tiers.create(tier).then(({data}) => {
            commit('create', data);
        });
    },
    update ({commit}, tier) {
        api.tiers.update(tier).then(({data}) => {
            commit('update', data);
        });
    },
    delete({commit}, tierId){
        api.tiers.delete(tierId).then(()=>{
            commit('delete', tierId);
        });
    },
};
