export default {
    fetch (state, tiers) {
        state.all = tiers;
    },

    create (state, tier) {
        state.all.push(tier);
    },

    update (state, tier) {
        const exId = state.all.findIndex(a => a.id == tier.id);
        if (exId >= 0) {
            state.all.splice(exId, 1, tier);
        }
    },
    delete(state, tierId){
        const exId = state.all.findIndex(a => a.id == tierId);
        if (exId >= 0) {
            state.all.splice(exId, 1);
        }
    }
};


