import api from '@/api';

// this object should contain methods
export default {
    fetch ({commit}) {
        api.billingentities.fetch().then(({data}) => {
            commit('fetch', data);
        });
    },
    create ({commit}, billingentity) {
        api.billingentities.create(billingentity).then(({data}) => {
            commit('create', data);
        });
    },
    update ({commit}, billingentity) {
        api.billingentities.update(billingentity).then(({data}) => {
            commit('update', data);
        });
    },
    setActiveBillingEntity({commit}, billingentityId){
        return commit('setActiveBillingEntity', billingentityId);
    },

    mapDivisions ({commit}, data) {
        api.billingentities.mapDivisions(data).then(({data}) => {
            return commit('update', data);
        });
    }
};
