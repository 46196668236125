import api from '@/api';

// this object should contain methods
export default {
  fetch ({commit}) {
    commit('setFetching', true);
    api.divisions.fetch().then(({data}) => {
      commit('fetch', data);
      commit('setFetching', false);
    });
  },
  fetchClientDivisions ({commit}, clientId) {
    return api.divisions.fetchClientDivisions(clientId).then(({data}) => {
      commit('fetch', data);
    });
  },
  create ({commit}, division) {
    api.divisions.create(division).then(({data}) => {
      commit('create', data);
    });
  },
  delete ({commit}, divisionId) {
    api.divisions.delete(divisionId).then(() => {
      commit('delete', divisionId);
    });
  },
  update ({commit}, division) {
    api.divisions.update(division).then(({data}) => {
      commit('update', data);
    });
  },
  duplicate ({commit}, formData) {
    return api.divisions.duplicate(formData).then(({data}) => {
      commit('fetch', data);
    });
  },
  load ({}, formData) {
    return api.divisions.load(formData);
  },
  mapPlans ({commit}, data) {
    api.divisions.mapPlans(data).then(({data}) => {

      return commit('update', data);
    });
  }
};
