import axios from 'axios';

export default {
    fetch() {
        return axios.get('/api/customattributes');
    },

    update(item){
        return axios.put(`/api/customattributes/${item.id}`, item);
    },

    setValues(form){
        return axios.post(`/api/attributevalues`, form);
    },

    getValues(form){
        return axios.get(`/api/attributevalues?entityType=${form.entityType}&entityId=${form.entityId}`);
    }
};
