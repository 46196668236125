import axios from 'axios';

export default {
    fetch () {
        return axios.get('/api/tierstructures');
    },
    create (tier) {
        return axios.post('/api/tierstructures', tier);
    },
    update (tier) {
        return axios.put(`/api/tierstructures/${tier.id}`, tier);
    },
    delete(tierId){
        return axios.delete(`/api/tierstructures/${tierId}`);
    },
};
