import api from '@/api';

// this object should contain methods
export default {
    fetchClientRates ({commit}, clientId) {
        return api.rates.fetchClientRates(clientId).then(({data}) => {
            commit('fetch', data);
        });
    },
    post ({commit}, rates) {
        return api.rates.post(rates).then(({data}) => {
            commit('fetch', data);
        });
    },
};
