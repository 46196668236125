import axios from 'axios';

export default {
    fetch () {
        return axios.get('/api/rates');
    },
    fetchClientRates (clientId) {
        return axios.get(`/api/rates?clientId=${clientId}`);
    },
    post (rates) {
        return axios.post('/api/rates', rates);
    },
};
