import api from '@/api';

// this object should contain methods
export default {
    fetch ({commit}) {
        api.customattributes.fetch().then(({data}) => {
            commit('fetch', data);
        });
    },

    update ({commit}, attribute) {
        api.customattributes.update(attribute).then(({data}) => {
            commit('update', data);
        });
    },

    setValues ({}, form) {
        return api.customattributes.setValues(form);
    },

    getValues ({}, form) {
        return api.customattributes.getValues(form);
    }
};
