export default {
  fetch (state, workflows) {
    state.all = workflows;
  },

  fetchItems (state, workflowItems) {
    state.items = workflowItems;
  },

  create (state, workflow) {
    state.all.push(workflow);
  },

  createItem (state, workflowItem) {
    state.items.push(workflowItem);
  },

  update (state, workflow) {
    const exId = state.all.findIndex(a => a.id == workflow.id);
    if (exId >= 0) {
      state.all.splice(exId, 1, workflow);
    }
  },

  updateItem (state, workflowItem) {
    const exId = state.items.findIndex(a => a.id == workflowItem.id);
    if (exId >= 0) {
      state.all.splice(exId, 1, workflowItem);
    }
  },

  load (state, data) {
    state.history.push(data);
  }
};
