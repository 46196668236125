<template>
  <div v-if="loggedIn">
    <v-navigation-drawer
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
    >
      <v-list dense>
        <template v-for="item in items">
          <v-row
              v-if="item.heading"
              :key="item.heading"
              align="center"
          >
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col
                cols="6"
                class="text-center"
            >
              <a
                  href="#!"
                  class="body-2 black--text"
              >EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
              v-else-if="item.children"
              :key="item.text"
              v-model="item.model"
              :prepend-icon="item.icon"
              append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
                v-for="(child, i) in item.children"
                :key="i"
                link
                class="ml-4"
                @click="followLink(child.link)"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
              v-else
              :key="item.text"
              link
              @click="followLink(item.link)"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <div class="audioPlayerWrapper" v-if="awesome">
        <vuetify-audio :file="audio.dare"></vuetify-audio>
      </div>
    </v-navigation-drawer>

  </div>

</template>

<script>
  import VuetifyAudio from 'vuetify-audio';
  import { mapState } from 'vuex';

  export default {
    name: 'LeftMenu',
    components: {
      VuetifyAudio,
    },
    props: {},
    data () {
      return {
        audio: {
          dare: '/audio/dare.mp3',
          transform: '/audio/autobots-3.mp3',
          menuopen: '/audio/menuopen.mp3',
        },
        playing: false,
        drawer: null,
        items: [
          {
            icon: 'mdi-home',
            text: 'Home',
            link: '/'
          },
          {
            icon: 'mdi-contacts',
            text: 'Clients',
            link: '/clients'
          },

          {
            icon: 'mdi-cog',
            text: 'Settings',
            link: '/settings',
            model: false,
            children: [
              {
                icon: 'mdi-alpha-q-circle-outline',
                text: 'Qualifiers',
                link: '/qualifiers'
              },
              {
                icon: 'mdi-alpha-r-circle-outline',
                text: 'Riders',
                link: '/riders'
              },
              {
                icon: 'mdi-head',
                text: 'Brokers',
                link: '/brokers'
              },
              {
                icon: 'mdi-form-textbox',
                text: 'Fields',
                link: '/fields'
              },
              {
                icon: 'mdi-form-textbox',
                text: 'Batch Load Types',
                link: '/batchloadtypes'
              },
              {
                icon: 'mdi-briefcase-outline',
                text: 'Custom Attributes',
                link: '/customattributes'
              },
              {
                icon: 'mdi-vector-polyline',
                text: 'Tiers',
                link: '/tiers'
              },
              {
                icon: 'mdi-sitemap',
                text: 'Workflows',
                link: '/workflows'
              },
            ]
          },
          {
            icon: 'mdi-shield-outline',
            text: 'Account',
            link: '/account'
          },
        ],
      };
    },

    computed: {
      ...mapState('user', {
        awesome: state => state.awesome,
        loggedIn: state => state.authenticated,
      }),
    },
    methods: {
      followLink (link) {
        if (this.awesome) {
          this.play(this.audio.menuopen);
        }
        this.$router.push(link).catch(() => {
        });
      },
      play (sound) {
        if (this.playing) {
          return;
        }
        this.playing = true;
        if (sound) {
          const audio = new Audio(sound);
          audio.play().then(() => {
            this.playing = false;
          });
        }
      }
    }
  };
</script>

<style scoped>
  .audioPlayerWrapper {
    margin: 20px;
  }
</style>
