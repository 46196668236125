export default {
    fetch (state, billingentities) {
        state.all = billingentities;
    },

    create (state, billingentity) {
        state.all.push(billingentity);
    },

    update (state, billingentity) {
        const exId = state.all.findIndex(a => a.id == billingentity.id);
        if (exId >= 0) {
            state.all.splice(exId, 1, billingentity);
        }
    },

    setActiveBillingEntity (state, billingentityId) {
        state.activeBillingEntityId = billingentityId;
    }
};
