import api from '@/api';

// this object should contain methods
export default {
    fetch({commit}) {
        api.refcodes.fetch().then(({data}) => {
            commit('fetch', data);
        });
    },
};
