import api from '@/api';

// this object should contain methods
export default {
  login ({commit, dispatch}, credentials) {
    return api.user.csrf().then(() => {
      return api.user.login(credentials)
        .then(() => {
          dispatch('fetchUserData');
          dispatch('setAuthValue', true);
        });
    });
  },

  fetchUserData ({commit}) {
    return api.user.userInfo().then(({data}) => {

      commit('setUserData', data);
    });
  },

  logout ({commit, dispatch}) {
    dispatch('setAuthValue', false);
    return api.user.logout()
      .then(() => {
        commit('clearUserData');
      });
  },

  setAuthValue ({commit}, value) {
    return commit('setAuthValue', value);
  },

  toggleAwesome ({commit}) {
    return commit('toggleAwesome');
  },

  setPassword ({}, password) {
    return api.user.updatePassword(password);
  }
};
