import axios from 'axios';

export default {
    fetch () {
        return axios.get('/api/plans');
    },
    create (plan) {
        return axios.post('/api/plans', plan);
    },
    update (plan) {
        return axios.put(`/api/plans/${plan.id}`, plan);
    },
    mapDivisions (data) {
        return axios.put(`/api/plans/${data.planId}?updateDivisions=true`,
            {
                divisions: data.divisions,
            });
    },
    fetchClientPlans(clientId){
        return axios.get(`/api/plans?clientId=${clientId}`);
    }
};
