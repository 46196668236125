import api from '@/api';

// this object should contain methods
export default {

  fetch ({commit}) {
    api.clients.fetch().then(({data}) => {
      commit('fetch', data);
    });
  },

  create ({commit}, client) {
    api.clients.create(client).then(({data}) => {
      commit('create', data);
    });
  },

  update ({commit}, client) {
    api.clients.update(client).then(({data}) => {
      commit('update', data);
    });
  },

  setActiveClient ({commit}, clientId) {
    return commit('setActiveClient', clientId);
  },

  load ({commit}, {
    clientId,
    production
  }) {
    api.clients.load(clientId, production).then(({data}) => {
      commit('loadResults', $data);
    });
  },

  fetchClientFiles ({commit}, clientId) {
    return api.clients.fetchClientFiles(clientId);
  },

  loadGroupFromSystem ({commit}, groupNumber) {
    return api.clients.loadGroupFromSystem({groupNumber}).then(({data}) => {
      return data.clientId;
    });
  }

};
