import api from '@/api';

// this object should contain methods
export default {
    fetch({commit}) {
        return api.qualifieritems.fetch().then(({data}) => {
            commit('fetch', data);
        });
    },
    create({commit}, qualifieritem){
        return api.qualifieritems.create(qualifieritem).then(({data})=>{
            commit('create', data);
        });
    },
    update ({commit}, qualifieritem) {
        return api.qualifieritems.update(qualifieritem).then(({data}) => {
            commit('update', data);
        });
    },
    delete({commit}, qualifieritemId){
        return api.qualifieritems.delete(qualifieritemId).then(()=>{
            commit('delete', qualifieritemId);
        });
    },

};
