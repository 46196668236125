export default {
  fetch (state, fields) {
    state.all = fields;
  },

  create (state, field) {
    state.all.push(field);
  },

  update (state, field) {
    const exId = state.all.findIndex(a => a.id == field.id);

    if (exId >= 0) {
      state.all.splice(exId, 1, field);
    }

  },

  setActiveField (state, fieldId) {
    state.activeFieldId = fieldId;
  },

  setActiveFieldType (state, fieldType) {
    state.activeFieldType = fieldType;
  },

  fetchTypes (state, types) {
    state.types = types;
  }
};
