import api from '@/api';

// this object should contain methods
export default {
    fetchTree ({commit}, clientId) {
        api.trees.fetchTree(clientId).then(({data}) => {
            commit('fetchTree', data);
        });
    },
};
