export default {
    fetch (state, qualifiers) {
        state.all = qualifiers;
    },

    create (state, qualifier) {
        state.all.push(qualifier);
    },

    update (state, qualifier) {
        const exId = state.all.findIndex(a => a.id == qualifier.id);
        if (exId >= 0) {
            state.all.splice(exId, 1, qualifier);
        }
    },

    delete(state, qualifierId){
        const exId = state.all.findIndex(a => a.id == qualifierId);
        if (exId >= 0) {
            state.all.splice(exId, 1);
        }
    }
};
