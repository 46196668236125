import api from '@/api';

// this object should contain methods
export default {
  fetch ({commit}) {
    api.workflows.fetch().then(({data}) => {
      commit('fetch', data);
    });
  },

  fetchItems ({commit}, workflowId) {
    api.workflows.fetchItems(workflowId).then(({data}) => {
      commit('fetchItems', data);
    });
  },
  create ({commit}, workflow) {
    api.workflows.create(workflow).then(({data}) => {
      commit('create', data);
    });
  },

  createItem ({commit}, workflowItem) {
    api.workflows.createItem(workflowItem).then(({data}) => {
      commit('createItem', data);
    });
  },

  update ({commit}, workflow) {
    api.workflows.update(workflow).then(({data}) => {
      commit('update', data);
    });
  },

  updateItem ({commit}, workflowItem) {
    api.workflows.updateItem(workflowItem).then(({data}) => {
      commit('updateItem', data);
    });
  },

  load ({commit}, brokerId) {
    api.workflows.load(brokerId).then(({data}) => {
      commit('loadResults', $data);
    });
  }
};
