import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      auth: false
    },
    component: () => import(/* webpackChunkName: "auth" */ '../views/Login.vue')
  },
  {
    path: '/fields',
    name: 'Fields',
    component: () => import(/* webpackChunkName: "fields" */ '../views/Fields.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/customattributes',
    name: 'Custom Attributes',
    component: () => import(/* webpackChunkName: "customattributes" */ '../views/CustomAttributes.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import(/* webpackChunkName: "clients" */ '../views/Clients.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/client/:id',
    name: 'Client',
    component: () => import(/* webpackChunkName: "clients" */ '../views/Client.vue'),
    meta: {
      auth: true
    },
    props: true
  },
  {
    path: '/rates/:clientId',
    name: 'Rates',
    component: () => import(/* webpackChunkName: "rates" */ '../views/Rates.vue'),
    meta: {
      auth: true
    },
    props: true
  },
  {
    path: '/brokers',
    name: 'Brokers',
    component: () => import(/* webpackChunkName: "brokers" */ '../views/Brokers.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/broker/:id',
    name: 'Broker',
    component: () => import(/* webpackChunkName: "brokers" */ '../views/Broker.vue'),
    meta: {
      auth: true
    },
    props: true
  },
  {
    path: '/tiers',
    name: 'Tiers',
    component: () => import(/* webpackChunkName: "tiers" */ '../views/Tiers.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/batchloadtypes',
    name: 'Batch Load Types',
    component: () => import(/* webpackChunkName: "tiers" */ '../views/BatchLoadTypes.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/tier/:id',
    name: 'Tier',
    component: () => import(/* webpackChunkName: "tiers" */ '../views/Tier.vue'),
    meta: {
      auth: true
    },
    props: true
  },
  {
    path: '/qualifiers',
    name: 'Qualifiers',
    component: () => import(/* webpackChunkName: "qualifiers" */ '../views/Qualifiers.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/riders',
    name: 'Riders',
    component: () => import(/* webpackChunkName: "riders" */ '../views/Riders.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/workflows',
    name: 'Workflows',
    component: () => import(/* webpackChunkName: "workflows" */ '../views/Workflows.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/workflow/:workflowId',
    name: 'Workflow',
    component: () => import(/* webpackChunkName: "workflows" */ '../views/Workflow.vue'),
    meta: {
      auth: true,
    },
    props: true,
  },

  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: {
      auth: true,
    }
  }

];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
});

export default router;
