export default {
    fetch (state, qualifiertypes) {
        state.all = qualifiertypes;
    },

    create (state, qualifiertype) {
        state.all.push(qualifiertype);
    },

    update (state, qualifiertype) {
        const exId = state.all.findIndex(a => a.id == qualifiertype.id);
        if (exId >= 0) {
            state.all.splice(exId, 1, qualifiertype);
        }
    },

    delete(state, qualifiertypeId){
        const exId = state.all.findIndex(a => a.id == qualifiertypeId);
        if (exId >= 0) {
            state.all.splice(exId, 1);
        }
    }
};
