import axios from 'axios';

export default {
    fetch () {
        return axios.get('/api/billingentities');
    },
    create (client) {
        return axios.post('/api/billingentities', client);
    },
    update(billingEntity){
      return axios.put(`/api/billingentities/${billingEntity.id}`, billingEntity);
    },
  delete(billingEntityId){
    return axios.delete(`/api/billingentities/${billingEntityId}`);
  },
    mapDivisions (data) {
        return axios.put(`/api/billingentities/${data.billingEntityId}?updateDivisions=true`,
          {
              divisions: data.divisions,
          });
    }
};
