import axios from 'axios';

export default {
  fetch () {
    return axios.get('/api/qualifieritems');
  },

  create (qualifieritem) {
    return axios.post('/api/qualifieritems', qualifieritem);
  },

  update (qualifieritem) {
    return axios.put(`/api/qualifieritems/${qualifieritem.id}`, qualifieritem);
  },

  delete(qualifieritemId){
    return axios.delete(`/api/qualifieritems/${qualifieritemId}`);
  },


};
