import api from '@/api';

// this object should contain methods
export default {
  fetch ({commit}) {
    api.fields.fetch().then(({data}) => {
      commit('fetch', data);
    });
  },
  create ({commit}, field) {
    api.fields.create(field).then(({data}) => {
      commit('create', data);
    });
  },
  update ({commit}, field) {
    api.fields.update(field).then(({data}) => {
      commit('update', data);
    });
  },
  setActiveField ({commit}, fieldId) {
    return commit('setActiveField', fieldId);
  },

  setActiveFieldType ({commit}, fieldType) {
    return commit('setActiveFieldType', fieldType);
  },

  downloadSettings ({}) {
    return api.fields.downloadSettings();
  },

  uploadSettings ({dispatch}, file) {
    return api.fields.uploadSettings(file).then(() => {
      dispatch('fetch');
    });
  },

  fetchTypes ({commit}) {
    return api.fields.fetchTypes().then(({data}) => {
      return commit('fetchTypes', data);
    });
  }
};
