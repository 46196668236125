import api from '@/api';

// this object should contain methods
export default {
    fetch ({commit}) {
        api.brokers.fetch().then(({data}) => {
            commit('fetch', data);
        });
    },

    create ({commit}, client) {
        api.brokers.create(client).then(({data}) => {
            commit('create', data);
        });
    },

    update ({commit}, client) {
        api.brokers.update(client).then(({data}) => {
            commit('update', data);
        });
    },

    load({commit}, brokerId, production = false){
        api.brokers.load(brokerId, production).then(({data})=>{
            commit('loadResults', $data);
        });
    }
};
