import api from '@/api';

// this object should contain methods
export default {
  fetch ({commit}) {
    return api.user.fetchApiTokens().then(({data}) => {
      commit('fetch', data);
    });
  },
  create ({commit}, tokenName) {
    return api.user.createApiToken({tokenName}).then(({data}) => {
      commit('create', data);
      return data;
    });
  },
  delete ({commit}, tokenId) {
    return api.user.deleteApiToken(tokenId).then(() => {
      commit('delete', tokenId);
    });
  }
};
