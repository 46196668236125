import axios from 'axios';

export default {
  fetch () {
    return axios.get('/api/fields');
  },
  update (fieldData) {
    return axios.put(`/api/fields/${fieldData.id}`, fieldData);
  },
  downloadSettings () {
    return axios.get('/api/fieldSettings/export', {
      responseType: 'blob',
      timeout: 30000,
    });
  },

  uploadSettings (file) {
    const formData = new FormData();
    formData.append('settingsFile', file);
    return axios.post('/api/fieldSettings/import', formData, {
      headers: {'Content-Type': 'multipart/form-data'}
    });
  },

  fetchTypes () {
    return axios.get('/api/loadfiles');
  }
};
