export default {
  lobs: state => {
    return state.all.filter((a) => a.type == 'LINEOFBUSINESS');
  },
  productTypes: state => {
    return state.all.filter((a) => a.type == 'PRODUCTTYPE');
  },
  tierTypes: state => {
    return state.all.filter((a) => a.type == 'TIERITEMTYPE');
  },
  coverageCodes: state => {
    return state.all.filter((a) => a.type == 'COVERAGE');
  },
  einTypes: state => {
    return state.all.filter((a) => a.type == 'EINType');
  }
};
