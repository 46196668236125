import Vue from 'vue';
import Vuex from 'vuex';

import apitokens from './apitokens';
import billingentities from './billingentities';
import brokers from './brokers';
import clients from './clients';
import customattributes from './customattributes';
import divisions from './divisions';
import fields from './fields';
import plans from './plans';
import qualifiers from './qualifiers';
import qualifieritems from './qualifieritems';
import qualifiertypes from './qualifiertypes';
import rates from './rates';
import refcodes from './refcodes';
import tiers from './tiers';
import trees from './trees';
import user from './user';
import workflows from './workflows';

import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: sessionStorage,
  //storage: window.localStorage,
  //storage: localForage,
  key: 'optimus',
  modules: [
    'apitokens',
    'billingentities',
    'brokers',
    'clients',
    'customattributes',
    'divisions',
    'fields',
    'plans',
    'qualifiers',
    'qualifieritems',
    'qualifiertypes',
    'rates',
    'refcodes',
    'tiers',
    'trees',
    'user',
    'workflows'
  ]
});

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    apitokens,
    billingentities,
    brokers,
    clients,
    customattributes,
    divisions,
    fields,
    plans,
    qualifiers,
    qualifieritems,
    qualifiertypes,
    rates,
    refcodes,
    tiers,
    trees,
    user,
    workflows
  },
  plugins: [ vuexLocal.plugin ]
});

export default store;
