import getters from './getters';
import actions from './actions';
import mutations from './mutations';

// default state object
const state = {
    all: [],
    activeDivisionId: 0,
    fetchingDivisions: false,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
