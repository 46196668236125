import axios from 'axios';

export default {
  fetch () {
    return axios.get('/api/divisions');
  },
  fetchClientDivisions (clientId) {
    return axios.get(`/api/divisions?clientId=${clientId}`);
  },
  create (division) {
    return axios.post('/api/divisions', division);
  },
  delete (divisionId) {
    return axios.delete(`/api/divisions/${divisionId}`);
  },
  update (division) {
    return axios.put(`/api/divisions/${division.id}`, division);
  },
  mapPlans (data) {
    return axios.put(`/api/divisions/${data.divisionId}?updatePlans=true`,
      {
        plans: data.plans,
      });
  },
  duplicate (data) {
    return axios.post('/api/division/duplicate', data);
  },
  load (formData) {
    return axios.post(`/api/division/load/${formData.divisionId}`, formData);
  }
};
