import axios from 'axios';

export default {
  fetch () {
    return axios.get('/api/qualifiers');
  },

  fetchClientQualifiers (clientId) {
    return axios.get(`/api/qualifiers?clientId=${clientId}`);
  },

  create (qualifier) {
    return axios.post('/api/qualifiers', qualifier);
  },

  update (qualifier) {
    return axios.put(`/api/qualifiers/${qualifier.id}`, qualifier);
  },

  delete(qualifierId){
    return axios.delete(`/api/qualifiers/${qualifierId}`);
  },

  mapPlans (data) {
    return axios.put(`/api/qualifiers/${data.qualifierId}?updatePlans=true`,
      {
        plans: data.plans,
      });
  }

};
