import api from '@/api';

// this object should contain methods
export default {
  fetch ({commit}) {
    api.qualifiertypes.fetch().then(({data}) => {
      commit('fetch', data);
    });
  },

  create ({commit}, qualifiertype) {
    return api.qualifiertypes.create(qualifiertype).then(({data}) => {
      commit('create', data);
    });
  },
  update ({commit}, qualifiertype) {
    api.qualifiertypes.update(qualifiertype).then(({data}) => {
      commit('update', data);
    });
  },
  delete ({commit}, qualifiertypeId) {
    api.qualifiertypes.delete(qualifiertypeId).then(() => {
      commit('delete', qualifiertypeId);
    });
  },

};
