import axios from 'axios';

export default {
  fetch () {
    return axios.get('/api/workflows');
  },

  fetchItems (workflowId) {
    return axios.get(`/api/workflowitems?workflowId=${workflowId}`);
  },

  fetchSingle (id) {
    return axios.get(`/api/workflows/${id}`);
  },

  create (workflow) {
    return axios.post('/api/workflows', workflow);
  },

  createItem (workflowItem) {
    return axios.post('/api/workflowitems', workflowItem);
  },

  update (workflow) {
    return axios.put(`/api/workflows/${workflow.id}`, workflow);
  },

  updateItem (workflowItem) {
    return axios.put(`/api/workflowitems/${workflowItem.id}`, workflowItem);
  }

};
