export default {
    fetch (state, plans) {
        state.all = plans;
    },

    create (state, plan) {
        state.all.push(plan);
    },

    update (state, plan) {
        const exId = state.all.findIndex(a => a.id == plan.id);
        if (exId >= 0) {
            state.all.splice(exId, 1, plan);
        }
    },
};
