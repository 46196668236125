import axios from 'axios';

export default {
    setUserData (state, userData) {
        state.info = userData;
    },

    clearUserData (state) {
        state.info = {};
    },
    setAuthValue(state, value){
        state.authenticated = value;
    },
    toggleAwesome(state){
        state.awesome = !state.awesome;
    }
};
