import axios from 'axios';

export default {
  fetch () {
    return axios.get('/api/clients');
  },

  create (client) {
    return axios.post('/api/clients', client);
  },

  update (clientData) {
    return axios.put(`/api/clients/${clientData.id}`, clientData);
  },

  load (clientId, production) {
    return axios.get(`/api/loadClient/${clientId}?prod=${production ? 'true' : 'false'}`);
  },
  loadGroupFromSystem (formData) {
    return axios.post(`/api/loadClientFromSystem`, formData);
  },
  fetchClientFiles (clientId) {
    console.log('api client id', clientId);
    return axios.get(`/api/loadClient/${clientId}?loadFile=true`);
  }
};
