export default {
  fetch (state, tokens) {
    state.all = tokens;
  },
  create (state, token) {
    state.all.push(token);
  },
  delete (state, tokenId) {
    const exId = state.all.findIndex(a => a.id == tokenId);
    if (exId >= 0) {
      state.all.splice(exId, 1);
    }
  }
};
