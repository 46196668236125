export default {
    fetch (state, clients) {
        state.all = clients;
    },

    create (state, client) {
        state.all.push(client);
    },

    update (state, client) {
        const exId = state.all.findIndex(a => a.id == client.id);
        if (exId >=0) {
            state.all.splice(exId, 1, client);
        }
    },

    load(state, data){
        state.history.push(data);
    }
};
